import { useState, useContext, lazy, Suspense, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet, Navigate, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
	getAuthToken,
	getUser,
	getRole,
	getName,
	getRoute,
	getSite,
	getMultipleSiteRoute,
	getSiteName,
	getWebPage,
} from "./utils";
import { AuthContext } from "./context";
import Main from "./left_bar/main";
import { getUserLogin } from "./api";
// import useIdleTimeout from "./left_bar/idleTimer";

// import cookie from "react-cookies";

const SignIn = lazy(() => import(/* webpackChunkName: "SignIn" */ "./signin/signIn"));
const SelectSites = lazy(() => import(/* webpackChunkName: "SelectSites" */ "./signin/selectSite"));
const NoMatch = lazy(() => import(/* webpackChunkName: "NoMatch" */ "./noMatch"));

const Map = lazy(() => import(/* webpackChunkName: "MeterMap" */ "./power_meter/map"));
const Datarate = lazy(() => import(/* webpackChunkName: "MeterMap" */ "./DataRate_map/map"));
const Raw = lazy(() => import(/* webpackChunkName: "Home" */ "./chiller/rawTable"));
const SixClass = lazy(() => import(/* webpackChunkName: "SixClass" */ "./class/mileChange"));
const OneDayData = lazy(() => import(/* webpackChunkName: "OneDayData" */ "./oneDay/oneDayData"));
const ClassControl = lazy(() => import(/* webpackChunkName: "ClassControl" */ "./classControl"));
const Heatpump = lazy(() => import(/* webpackChunkName: "Heatpump" */ "./heat/heatPump"));
const AbnormalStreetlight = lazy(() =>
	import(/* webpackChunkName: "abnormalstreetlight" */ "./email_streetlight/streetlight.js")
);
const Water = lazy(() => import(/* webpackChunkName: "water" */ "./water_degree/water"));
const Streetlight = lazy(() => import(/* webpackChunkName: "streetlight" */ "./streetlight/streetlight"));
const Analyze = lazy(() => import(/* webpackChunkName: "Analyze" */ "./analyze/analyze"));

//0406更新
const AbnormalCornlight = lazy(() =>
	import(/* webpackChunkName: "abnormalcornlight" */ "./email_streetlight/cornlight.js")
);
const Cornlight = lazy(() => import(/* webpackChunkName: "cornlight" */ "./streetlight/cornlight"));
const PHPtable = lazy(() => import(/* webpackChunkName: "PHPtable" */ "./table_data/tableroute"));
const UserAccount = lazy(() => import(/* webpackChunkName: "UserAccount" */ "./account/userAccount"));
const EditAccount = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./account/accountedit"));
const Dormitory = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./heat_for_student/heat.js"));
const Reporter = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./all_report/datereporter.js"));
const ChillerReporter = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./chillerReport/table.js"));
const DeviceStatus = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./deviceStatus/table.js"));
const Warn = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./warn/table.js"));
const ChillerAnalyze = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./analyzeXY/datereporter.js"));
const TempControl = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./temp_control/controlpage.js"));
const HeatPumpReport = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./heatpump_report/heatreport.js"));
const ChillerReport = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./chiller_analyze/report.js"));
const MalfunctionPM = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./pm_malfunction/report.js"));
const Malfunction = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./malfunction/report.js"));
const DataChart = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./data_chart/datereporter.js"));
const AnalyzeXYnew = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./analyzeXYnew/datereporter.js"));
const LightSystem = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./light_system/streetlight.js"));
const ChillerSystemNew = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./chiller_new/googlemap_dot.js"));
const HeatPumpSystemNew = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./heatpump_new/googlemap_dot.js"));
const ESS = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./ess2/main.js"));
const Solar = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./solar/solar/main.js"));
const SolarWarning = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./solar/solar_warning/main.js"));
const SolarIncome = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./solar/solar_income/main.js"));

const SolarAVGPower = lazy(() => import(/* webpackChunkName: "EditAccount" */ "./solar/avgGenPower/main.js"));

//偵測AuthContext是否為空，為空則返回signin。AuthContext會在signin頁面觸發

const PrivateRoute = ({ children }) => {
	const { tokenValue } = useContext(AuthContext);
	// console.log(tokenValue);
	return tokenValue ? <Outlet /> : <Navigate to="/signin" />;
};
const PrivilegeRoute = (e) => {
	const tokenValue = useContext(AuthContext);
	if (tokenValue && (e.role === "super_admin" || e.role === "admin")) {
		console.log("allow!!");
	} else {
		console.log("forbidden!!");
	}
	return tokenValue && (e.role === "super_admin" || e.role === "admin") ? <Outlet /> : <Navigate to="/" />;
};
const SuperprivilegeRoute = (e) => {
	const tokenValue = useContext(AuthContext);
	if (tokenValue && e.role === "super_admin") {
		console.log("allow!!");
	} else {
		console.log("forbidden!!");
	}
	return tokenValue && e.role === "super_admin" ? <Outlet /> : <Navigate to="/" />;
};

const allroute = [
	{ id: 1, system: "chiller", route: "chiller", element: <Raw /> },
	{ id: 2, system: "chiller", route: "class", element: <SixClass /> },
	{ id: 3, system: "chiller", route: "class_control", element: <ClassControl /> },
	{ id: 4, system: "streetlight", route: "streetlight", element: <Streetlight /> },
	{ id: 5, system: "streetlight", route: "cornlight", element: <Cornlight /> },
	{ id: 6, system: "powerMeter", route: "meter", element: <Map /> },
	{ id: 6, system: "powerMeter", route: "powerlist", element: <PHPtable /> },
	{ id: 7, system: "powerMeter", route: "meter_analyze", element: <Analyze /> },
	{ id: 8, system: "analyze", route: "datarate", element: <Datarate /> },
	{ id: 9, system: "heatPump", route: "heat", element: <Heatpump /> },
	{ id: 10, system: "heatPump", route: "water", element: <Water /> },
	{ id: 11, system: "analyze", route: "oneday", element: <OneDayData /> },
	{ id: 12, system: "analyze", route: "report", element: <Reporter /> },
	{ id: 13, system: "chiller", route: "chiller_report", element: <ChillerReporter /> },
	{ id: 17, system: "analyze", route: "analyze", element: <ChillerAnalyze /> },
	{ id: 18, system: "chiller", route: "temp_control", element: <TempControl /> },
	{ id: 19, system: "heatPump", route: "heatpump_report", element: <HeatPumpReport /> },
	{ id: 15, system: "analyze", route: "warn_cc", element: <Warn showDelete={true} /> },
	{ id: 20, system: "chiller", route: "chiller_analyze", element: <ChillerReport /> },
	{ id: 21, system: "analyze", route: "pm_malfunction", element: <MalfunctionPM /> },
	{ id: 22, system: "analyze", route: "malfunction", element: <Malfunction /> },
	{ id: 23, system: "analyze", route: "data_chart", element: <DataChart /> },
	{ id: 24, system: "analyze", route: "xyz_analyze_new", element: <AnalyzeXYnew /> },
	{ id: 25, system: "streetlight", route: "light_system", element: <LightSystem /> },
	{ id: 26, system: "chiller", route: "chiller_new", element: <ChillerSystemNew /> },
	{ id: 27, system: "heatPump", route: "heatPump_new", element: <HeatPumpSystemNew /> },
	{ id: 28, system: "solar", route: "solar", element: <Solar /> },
	{ id: 29, system: "solar", route: "solar_warning", element: <SolarWarning /> },
	{ id: 30, system: "solar", route: "solar_income", element: <SolarIncome /> },
	{ id: 31, system: "solar", route: "solar_avgPower", element: <SolarAVGPower /> },

];

const allroutes = allroute.map((e) => ({ id: e.id, system: e.system, route: e.route }));

// const Chiller = ({ chill }) => {
//     //理論上這邊要進行一個簡單的訪問（比如chill右邊的詳細資料取得）
//     // return chill !== null ? <Outlet /> : <Navigate to="/raw" />;
//     return <Outlet />;
// };

//以下是用最簡單的方式來進行token認證：AuthContext.Provider底下的所有route都能共享被儲存在裡面的資訊，
const App = () => {
	//初始值需要自動要資料，不然會因為權限不通過跳轉到首頁
	const navigate = useNavigate();
	const [userRole, setUserRole] = useState(getRole());
	const [tokenValue, setTokenValue] = useState(getAuthToken());
	const [userValue, setUserValue] = useState(getUser());
	const [userName, setUserName] = useState(getName());
	const route = getRoute();

	const [site, setUserSite] = useState(getSite());
	const [siteName, setUserSiteName] = useState(getSiteName());
	const [chillerRow, setChillerRow] = useState([]);
	const [googleAPI, setGoogleAPI] = useState("123");
	// const [loginStatus, setLoginStatus] = useState(1);
	const [multipleRoute, setMultipleRoute] = useState(getMultipleSiteRoute());
	const [page, setPage] = useState(getWebPage());

	const final_route = allroute.filter((e) => route.includes(e.id.toString()));
	const final_routes = allroutes.filter((e) => route.includes(e.id.toString()));

	useEffect(() => {
		// console.log(process.env.PUBLIC_URL);
		// console.log(route);
		getUserLogin("/mapAPI")
			.then((res) => {
				setGoogleAPI(res.data.keys);
				// setGoogleAPI("");
			})
			.catch((err) => console.log(err));
	}, []);
	// console.log(site.split(','));
	useEffect(() => {
		getUserLogin(`/loginStatus/${userValue}`)
			.then((res) => {
				if (res.data.loginStatus === 0) {
					localStorage.clear();
					navigate("/signin");
				}
			})
			.catch((err) => console.log(err));
	}, []);

	// const logout = () => {
	// 	console.log("User got logged out");
	// 	localStorage.clear();
	// 	setPage(null);
	// 	navigate("/signin");
	// };
	//計時器登出功能，目前可以直接調用
	// const { isIdle } = useIdleTimeout({ onIdle: logout, idleTime: 30 * 60 });

	return (
		googleAPI !== "123" && (
			<AuthContext.Provider
				value={{
					googleAPI,
					allroutes,
					final_routes,
					tokenValue,
					setTokenValue,
					userValue,
					setUserValue,
					chillerRow,
					setChillerRow,
					userRole,
					setUserRole,
					userName,
					setUserName,
					site,
					setUserSite,
					multipleRoute,
					setMultipleRoute,
					siteName,
					setUserSiteName,
					page,
					setPage,
				}}
			>
				<Suspense
					fallback={
						<div
							style={{
								textAlign: "center",
								marginTop: 200,
							}}
						>
							           loading...          
						</div>
					}
				>
					<Routes>
						<Route path="/signin" element={<SignIn />} />
						<Route path="/ess" element={<ESS />} />
						<Route path="/abnormalstreetlight" element={<AbnormalStreetlight />} />
						<Route path="/abnormalcornlight" element={<AbnormalCornlight />} />
						<Route path="/selectSites" element={<SelectSites />} />
						<Route path="/dormitory" element={<Dormitory />} />
						<Route path="/device_status" element={<DeviceStatus />} />
						<Route path="/warn" element={<Warn />} />
						<Route path="*" element={<NoMatch />} />
						<Route path="/" element={<PrivateRoute />}>
							<Route path="/" element={<NoMatch />} />
							{final_route.map((e) => (
								<Route key={e.route} path={"/" + e.route} element={<Main component={e.element} />} />
							))}
							<Route path="/userAccount/*" element={<Main component={<UserAccount />} />} />
							<Route path="/editAccount" element={<Main component={<EditAccount />} />} />
						</Route>
					</Routes>
				</Suspense>
			</AuthContext.Provider>
		)
	);
};

export default function Finalapp() {
	return (
		<Router basename={process.env.PUBLIC_URL}>
			<App />
		</Router>
	);
}
