import { useState, useContext } from "react";
import { setWebPage } from "../utils.js";
import { AuthContext } from "../context.js";
import { useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AvatarMobile from "./avatarMobile";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import WaterIcon from "@mui/icons-material/Water";
import SummarizeIcon from "@mui/icons-material/Summarize";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import AcUnitIcon from "@mui/icons-material/AcUnit";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import TungstenIcon from "@mui/icons-material/Tungsten";
import Tooltip from "@mui/material/Tooltip";
import WifiTetheringIcon from "@mui/icons-material/WifiTethering";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import InsightsIcon from "@mui/icons-material/Insights";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import EmailIcon from "@mui/icons-material/Email";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

// import cookie from "react-cookies";

export default function SecondaryListItems(props) {
	let navigate = useNavigate();
	// const site = cookie.load("site");

	//在此處定義一個物件陣列，然後在site處傳入一個只包含數字的陣列。此陣列將根據ID列出左側列表哪些要列出。
	const { final_routes, setPage, page } = useContext(AuthContext);
	// console.log(final_routes);

	const systemItem = [
		{ id: 1, system: "chiller", icon: <AcUnitIcon fontSize="large" />, text: "冰機系統" },
		{ id: 2, system: "heatPump", icon: <LocalFireDepartmentIcon fontSize="large" />, text: "熱泵系統" },
		{ id: 3, system: "powerMeter", icon: <ElectricMeterIcon fontSize="large" />, text: "電錶系統" },
		{ id: 4, system: "streetlight", icon: <TungstenIcon fontSize="large" />, text: "路燈系統" },
		{ id: 5, system: "class", icon: <HolidayVillageIcon fontSize="large" />, text: "教室CO2系統" },
		{ id: 6, system: "analyze", icon: <InsightsIcon fontSize="large" />, text: "資料分析" },
		{ id: 7, system: "solar", icon: <SolarPowerIcon fontSize="large" />, text: "太陽能系統" },

	];

	const listItem = [
		{ id: 1, icon: <AcUnitIcon sx={{ fontSize: 25 }} />, text: "冰機即時資訊" },
		{ id: 2, icon: <HolidayVillageIcon sx={{ fontSize: 25 }} />, text: "各教室即時資訊" },
		{ id: 9, icon: <LocalFireDepartmentIcon sx={{ fontSize: 25 }} />, text: "熱泵即時資訊" },
		{ id: 6, icon: <ElectricMeterIcon sx={{ fontSize: 25 }} />, text: "電錶檢視" },
		{ id: 4, icon: <TungstenIcon sx={{ fontSize: 25 }} />, text: "路燈檢視" },
		{ id: 25, icon: <EmailIcon sx={{ fontSize: 25 }} />, text: "路燈系統設定" },
		{ id: 11, icon: <QueryStatsIcon sx={{ fontSize: 25 }} />, text: "今日資料查詢" },
		{ id: 8, icon: <WifiTetheringIcon sx={{ fontSize: 25 }} />, text: "回報率查詢" },
		{ id: 10, icon: <WaterIcon sx={{ fontSize: 25 }} />, text: "水位查詢" },
		{ id: 12, icon: <SummarizeIcon sx={{ fontSize: 25 }} />, text: "往日資料查詢" },
		{ id: 13, icon: <AutoModeIcon sx={{ fontSize: 25 }} />, text: "冰機資料查詢（測試）" },
		{ id: 15, icon: <SummarizeIcon sx={{ fontSize: 25 }} />, text: "告警" },
		{ id: 16, icon: <SummarizeIcon sx={{ fontSize: 25 }} />, text: "告警" },
		{ id: 17, icon: <InsightsIcon sx={{ fontSize: 25 }} />, text: "資料分析" },
		{ id: 19, icon: <LibraryBooksIcon sx={{ fontSize: 25 }} />, text: "熱泵報告" },
		{ id: 20, icon: <AssessmentIcon sx={{ fontSize: 25 }} />, text: "冰機分析報告" },
		{ id: 21, icon: <MedicalInformationIcon sx={{ fontSize: 25 }} />, text: "設備及故障相關" },
		{ id: 22, icon: <MedicalInformationIcon sx={{ fontSize: 25 }} />, text: "設備及故障相關" },
		{ id: 23, icon: <MultilineChartIcon sx={{ fontSize: 25 }} />, text: "資料可視化圖表" },
		{ id: 24, icon: <ScatterPlotIcon sx={{ fontSize: 25 }} />, text: "資料分析(新)" },
		{ id: 26, icon: <AcUnitIcon sx={{ fontSize: 25 }} />, text: "冰機系統(新)" },
		{ id: 27, icon: <LocalFireDepartmentIcon sx={{ fontSize: 25 }} />, text: "熱泵系統(新)" },
		{ id: 28, icon: <LocalFireDepartmentIcon sx={{ fontSize: 25 }} />, text: "太陽能系統首頁" },
		{ id: 29, icon: <LocalFireDepartmentIcon sx={{ fontSize: 25 }} />, text: "案場告警" },
		{ id: 30, icon: <LocalFireDepartmentIcon sx={{ fontSize: 25 }} />, text: "案場收益" },
		{ id: 31, icon: <LocalFireDepartmentIcon sx={{ fontSize: 25 }} />, text: "最高平均發電" },

	];
	//得到final_routes的有效頁面
	const first_filter = final_routes.filter((e) => listItem.map((e) => e.id).includes(e.id));
	//得到system底下的page
	const system = {};
	const system_page_route = [];
	for (let i = 0; i < first_filter.length; i++) {
		if (!system[first_filter[i].system]) {
			system[first_filter[i].system] = {};
			const system_iconIndex = systemItem.findIndex((e) => e.system === first_filter[i].system);
			system_page_route.push({
				system: first_filter[i].system,
				text: systemItem[system_iconIndex].text,
				icon: systemItem[system_iconIndex].icon,
				page_route: [],
			});
		}
		if (!system[first_filter[i].system][first_filter[i].id]) {
			system[first_filter[i].system][first_filter[i].id] = 1;
			const systemIndex = system_page_route.findIndex((e) => e.system === first_filter[i].system);
			const page_iconIndex = listItem.findIndex((e) => e.id === first_filter[i].id);
			system_page_route[systemIndex].page_route.push({
				...first_filter[i],
				icon: listItem[page_iconIndex].icon,
				text: listItem[page_iconIndex].text,
			});
		}
	}
	const NavButton = ({ icon, text, page_route }) => {
		const [open, setOpen] = useState(false);

		const handleClick = () => {
			setOpen(!open);
		};

		return (
			<>
				<Tooltip title={<h2 style={{ color: "lightblue" }}>{text}</h2>} placement="right">
					<ListItemButton sx={{ borderBottom: "1px solid #eee" }} onClick={handleClick}>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primary={text} secondary={"點擊展開頁面 "} />
						{open ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
				</Tooltip>
				<Collapse in={open} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						{page_route.map((e) => {
							return (
								<Tooltip key={e.id} title={<h2 style={{ color: "lightblue" }}>{e.text}</h2>} placement="right">
									<ListItemButton
										sx={{
											pl: 4,
											backgroundColor: page == e.id ? "#f5f5f5" : "#fafafa",
											borderRight: page != e.id ? "1px solid rgba(0, 0, 0, 0.12)" : "",
										}}
										onClick={() => {
											setWebPage(e.id);
											setPage(e.id);
											navigate("/" + e.route);
										}}
									>
										<ListItemIcon sx={{ color: page == e.id ? "#0288d1" : "", fontSize: 20 }}>{e.icon}</ListItemIcon>
										<ListItemText primary={<Typography variant="subtitle1" sx={{color: page == e.id ? "#0288d1" : ""}}>{e.text}</Typography>} />
									</ListItemButton>
								</Tooltip>
							);
						})}
					</List>
				</Collapse>
			</>
		);
	};

	return (
		<>
			<AvatarMobile open={props.open} navigation={props.navigation} />
			{system_page_route.map((e) => (
				<NavButton key={e.system} icon={e.icon} text={e.text} page_route={e.page_route} />
			))}
		</>
	);
}
